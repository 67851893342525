import {CLEAR_USER, SET_USER} from "../types";

const userReducer = (state = {}, action) => {
    switch (action?.type) {
        case SET_USER:
            localStorage.setItem('currentUser', JSON.stringify(action?.payload));
            return action?.payload;
        case CLEAR_USER:
            localStorage.removeItem('currentUser')
            return null;
        default:
            return JSON.parse(localStorage.getItem('currentUser'));
    };
}
export default userReducer;
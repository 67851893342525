import {LOGIN_URL} from "../../aa-common/Constants";

export const handleGetResponse = (response) => {

    if (response.status === 403) {
        document.location.replace('/403');
        return null;
    }

    //Handles authentication required
    if (response.redirected && response.url && response.url === LOGIN_URL()) {
        localStorage.removeItem("currentUser");
        // if (response.redirected && response.url) {
        let url = response.url;
        document.location.replace(url);
        return null;
    }

}

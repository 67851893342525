import {useEffect, useState} from "react";
import {DataTable, Text} from "grommet";
import TestCustomerService from "../../service/test/TestCustomerService";

const TestCustomerSecuredPage = () => {
    const [customerList, setCustomerList] = useState([]);

    const returnUrlIfAuthenticationWasRequired = window.location.href;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await TestCustomerService.getCustomersSecured(returnUrlIfAuthenticationWasRequired);
                if (data !== null) {
                    setCustomerList(data);
                }
            } catch (error) {
                //TODO: handle error
            }
        };

        fetchData();
    }, [returnUrlIfAuthenticationWasRequired, setCustomerList]);
    return (
        <DataTable
            columns={[
                {
                    property: 'firstName',
                    header: <Text>Name</Text>,
                },
                {
                    property: 'lastName',
                    header: <Text>Last name</Text>,
                },
                {
                    property: 'email',
                    header: <Text>Email</Text>,
                }

            ]}
            data={
                customerList
                // [
                //     { name: 'Alan', lastName: 'John' , email: 'johnny@test.com'},
                //     { name: 'Bryan', lastName: 'Random', email: 'random@ok.org' },
                //     { name: 'Chris', lastName: 'Tester', email: 'tester@testers.net' },
                //     { name: 'Eric', lastName: 'okay' , email: 'okayisnotokay@help.cf'},
                // ]
            }
        />
    )
}
export default TestCustomerSecuredPage;
import {Anchor, Box, Button, Header, Menu, Nav, ResponsiveContext} from "grommet";
import {Moon, Sun} from "grommet-icons";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleTheme} from "../store/actions/theme";
import {LOGIN_URL} from "../aa-common/Constants";

const NavigationBar = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector(state => state.theme);

    const handleThemeChange = () => {
        dispatch(toggleTheme(!darkMode));
    }

    const LoginAnchor = () => {
        return (
            <Anchor href={LOGIN_URL()} label="Login"/>
        )
    }
    const HowItWorksAnchor = () => {
        return (
            <Anchor href="#" label="How it works"/>
        )
    }

    return (
        <Header
            background={darkMode ? "light-3" : "dark-1"}
            pad={{left: "medium", right: "small", vertical: "small"}}
            elevation="medium"
        >
            <Anchor href="/" size="large">Helpify</Anchor>

            <Box direction="row" justify="center" gap="medium" align="center">
                <ResponsiveContext.Consumer>
                    {(responsive) =>
                        responsive === 'small' ? (
                            <Menu
                                label=""
                                items={[
                                    {
                                        label: <HowItWorksAnchor/>, onClick: () => {
                                        }
                                    },
                                    {
                                        label: <LoginAnchor/>, onClick: () => {
                                        }
                                    },

                                ]}
                            />
                        ) : (
                            <Nav justify="center" align="end" direction="row">
                                <HowItWorksAnchor/>
                                <LoginAnchor/>
                            </Nav>
                        )
                    }
                </ResponsiveContext.Consumer>
                <Button
                    a11yTitle={darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                    icon={darkMode ? <Moon/> : <Sun/>}
                    onClick={() => handleThemeChange()}
                    // tip={{
                    //     content: (
                    //         <Box
                    //             pad="small"
                    //             round="small"
                    //             background={darkMode ? "dark-1" : "light-3"}
                    //         >
                    //             {darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                    //         </Box>
                    //     ),
                    //     plain: true,
                    // }}
                />
            </Box>


        </Header>
    )
}
export default NavigationBar;
import {BASE_API_URL} from "../../aa-common/Constants";
import {handleGetResponse} from "../a-rest-commons/httpHandler";

const API_URL = BASE_API_URL() + '/test/customer';

class TestCustomerService {
    async getCustomers(returnUrlIfAuthenticationWasRequired) {
        try {
            const response = await fetch(
                API_URL,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'ui-url': returnUrlIfAuthenticationWasRequired,
                    },
                    redirect: 'follow',
                    credentials: 'include',
                }
            );
            console.log("TestCustomerService response:", response)
            handleGetResponse(response);
            return await response.json();
        } catch (error) {
            console.log("caught error:", error);
            throw error;
        }
    }

    async getCustomersSecured(returnUrlIfAuthenticationWasRequired) {

        try {
            const response = await fetch(
                API_URL + '/secured',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'ui-url': returnUrlIfAuthenticationWasRequired,
                    },
                    redirect: 'follow',
                    credentials: 'include',
                }
            );
            handleGetResponse(response);
            return await response.json();
        } catch (error) {
            throw error;
        }
    }
}

const instance = new TestCustomerService();

export default instance;

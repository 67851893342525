import {SET_DARK_MODE,CLEAR_DARK_MODE} from "../types";

const themeReducer = (state = {}, action) => {
    switch (action?.type) {
        case SET_DARK_MODE:
            localStorage.setItem('dark', JSON.stringify(action?.payload));
            return action?.payload;
        case CLEAR_DARK_MODE:
            localStorage.removeItem('dark')
            return null;
        default:
            return JSON.parse(localStorage.getItem('dark'));
    };
};

export default themeReducer;
export const ENVIRONMENT = "PROD";
export const BASE_API_URL = () => {
    if (ENVIRONMENT === "DEV") {
        return "http://localhost:8080/ops/api/v1"
    }
    if (ENVIRONMENT === "PROD") {
        return "https://helpify.lt/ops/api/v1"
    }
}
export const LOGIN_URL = () => {
    if (ENVIRONMENT === "DEV") {
        return "http://localhost:8080/ops/in"
    }
    if (ENVIRONMENT === "PROD") {
        return "https://helpify.lt/ops/in"
    }
}




import themeReducer from "./reducers/theme";
import userReducer from "./reducers/user";
import {configureStore} from "@reduxjs/toolkit";

export const store = configureStore({
        reducer: {
            theme: themeReducer,
            user: userReducer,
        }
    }
);

import {grommet, Grommet,} from "grommet";
import React, {useEffect} from "react";
import {deepMerge} from "grommet/utils";
import NavigationBar from "./components/NavigationBar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFoundPage from "./pages/error/NotFoundPage";
import UnauthorizedPage from "./pages/error/UnauthorizedPage";
import HomePage from "./pages/home/HomePage";
import TestCustomerPage from "./pages/test/TestCustomerPage";
import TestCustomerSecuredPage from "./pages/test/TestCustomerSecuredPage";
import {useDispatch, useSelector} from "react-redux";
import {themeConfig} from "./theme/theme";
import AuthenticationService from "./service/authentication/AuthenticationService";
import {setUser} from "./store/actions/user";

const theme = deepMerge(grommet, themeConfig);
const App = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user);
// TODO: THIS COULD CAUSE ISSUE WITH REDIRECTING AFTER SUCCESSFUL LOGIN! KEEP AN EYE ON IT!
    useEffect(() => {
            if (AuthenticationService.needToAuthenticate(currentUser)) {
                AuthenticationService.getAuthentication().then(response => {
                    console.log("Response from GetCurrentUser: ", response);
                    dispatch(setUser(response.data));
                }).catch(error => {
                    console.log(error);
                });
            }
        }
        , [dispatch, currentUser]);

    const darkMode = useSelector(state => state.theme);
    return (
        <Grommet theme={theme} full themeMode={darkMode ? "dark" : "light"}>
            <BrowserRouter>
                <NavigationBar/>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/test/customer" element={<TestCustomerPage/>}/>
                    <Route path="/test/customer/secured" element={<TestCustomerSecuredPage/>}/>

                    <Route path="/404" element={<NotFoundPage/>}/>
                    <Route path="/403" element={<UnauthorizedPage/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </BrowserRouter>
        </Grommet>
    );
}

export default App;

import {BASE_API_URL} from "../../aa-common/Constants";
import axios from "axios";

const API_URL = BASE_API_URL() + '/auth';

class AuthenticationService {
    getAuthentication() {
        return axios.get(API_URL, {withCredentials: true});
    }

    needToAuthenticate(currentUser) {
        let needToAuthenticate = false;
        if (currentUser !== null) {
            if (currentUser.username === undefined || currentUser.username === null || currentUser.username === "") {
                console.log("needToAuthenticate: currentUser.username is empty")
                needToAuthenticate = true;
            }
            if (currentUser.exp !== undefined && currentUser.exp !== null && currentUser.exp !== "") {
                let now = new Date();
                let expireDate = new Date(currentUser.exp * 1000);
                if (expireDate < now) {
                    console.log("needToAuthenticate: currentUser.exp is expired")
                    needToAuthenticate = true;
                }
            } else {
                console.log("needToAuthenticate: currentUser.exp is empty")
                needToAuthenticate = true;
            }
        } else {
            console.log("needToAuthenticate: currentUser is empty")
            needToAuthenticate = true;

        }
        console.log("needToAuthenticate: ", needToAuthenticate)
        return needToAuthenticate;
    }
}

const instance = new AuthenticationService();

export default instance;
import {useEffect, useState} from "react";
import {DataTable, Text} from "grommet";
import TestCustomerService from "../../service/test/TestCustomerService";

const TestCustomerPage = () => {
    const [customerList, setCustomerList] = useState([]);
    const [showCustomers, setShowCustomers] = useState(false);

    const returnUrlIfAuthenticationWasRequired = window.location.href;
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const data = await TestCustomerService.getCustomers(returnUrlIfAuthenticationWasRequired);
                console.log("response.json data: ", data)
                if (data !== null) {
                    setCustomerList(data);
                    setShowCustomers(true)
                }
            } catch (error) {
                //TODO: handle error
            }
        };
        fetchCustomers();
    }, [returnUrlIfAuthenticationWasRequired]);
    return (
        <>
            {showCustomers &&
                <DataTable
                    columns={[
                        {
                            property: 'firstName',
                            header: <Text>Name</Text>,
                        },
                        {
                            property: 'lastName',
                            header: <Text>Last name</Text>,
                        },
                        {
                            property: 'email',
                            header: <Text>Email</Text>,
                        }

                    ]}
                    data={
                        customerList
                    }
                />
            }
        </>
    )
}
export default TestCustomerPage;